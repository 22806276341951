export const SUPER_USER1 = "hairposey@gmail.com";
export const SUPER_USER2 = "tisijola7@gmail.com";
export const SHIPPING_COST = {
	canada: 9,
	usa: 20,
	london: 20,
};
export const CANADA = "canada";
export const USERS = "users";
export const BOOKINGS = "bookings";
export const CURRENCY = "cad";
export const REACT_APP_BOOK = "/hairposey/booking";
export const REACT_APP_SHOP = "/hairposey/shopping";
export const CLIENT_URL = "http://localhost:3001";
export const REACT_APP_ISBOOKING = "booking";
export const REACT_APP_BOOKLIMIT = 7;
export const REACT_APP_ISSHOPPING = "shopping";
export const REACT_APP_STATUS = "pending";
export const REACT_APP_CANCELED = "canceled";
// dev
// export const REACT_APP_SITE_URL = "http://localhost:8888/customer/";
// dev
// export const REACT_APP_HTTPS_LINK = "http://localhost:5000";
// Prod
export const REACT_APP_SITE_URL = "https://www.hairposey.com/customer/";
// // prod
export const REACT_APP_HTTPS_LINK = "https://www.hairposey.com";
export const REACT_APP_AUTHORIZED_ID_ONE = "hairposey@gmail.com";
export const REACT_APP_AUTHORIZED_ID_TWO = "tisijola7@gmail.com";

export const sizes = {
	small: "xs",
	medium: "md",
	large: "xl",
	xlarge: "2xl",
	xxlarge: "3xl",
};

export const information = ["category", "add on services", "services"];
