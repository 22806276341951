import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/home-page";
import Register from "./pages/register";
import Login from "./pages/login";
import NotFound from "./pages/error";
import Canceled from "./pages/canceled";
import Success from "./pages/success";
import Checkout from "./pages/checkout";
import Payment from "./pages/payment";
import Management from "./pages/management";
import HairBundles from "./pages/hair-bundles";
import ClosureFrontal from "./pages/closure-frontal";
import JetBlackHair from "./pages/jet-black-hairs";
import Cart from "./pages/cart";
import Account from "./pages/account";
import Calendar from "./pages/calendar";
import SingleBookPage from "./pages/singleBookPage";

function App() {
	return (
		<Routes>
			{/* General route path */}
			<Route exact path="/register" element={<Register />} />
			<Route exact path="/login" element={<Login />} />
			<Route exact path="/success" element={<Success />} />
			<Route exact path="/canceled" element={<Canceled />} />
			<Route exact path="/user-account/:userInfo" element={<Account />} />
			<Route exact path="/admin-portal" element={<Management />} />
			<Route exact path="/user-cart/:userId" element={<Cart />} />
			<Route exact path="/checkout/:userId" element={<Checkout />} />
			<Route exact path="/payment" element={<Payment />} />
			<Route exact path="/hairposey/:pathname" element={<HomePage />} />
			<Route exact path="/calendar" element={<Calendar />} />
			<Route exact path="/customer/:bookId" element={<SingleBookPage />} />

			{/* Hair route path */}
			<Route exact path="/bundles/:brand" element={<HairBundles />} />
			<Route
				exact
				path="/closure-frontal/:brand"
				element={<ClosureFrontal />}
			/>
			<Route
				exact
				path="/jet-black-&-blonde/:brand"
				element={<JetBlackHair />}
			/>

			{/* Home and error route path */}
			<Route exact path="*" element={<NotFound />} />
			<Route exact path="/" element={<HomePage />} />
		</Routes>
	);
}

export default App;
